<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="修改任务"
      width="600px"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item v-if="taskType !== 'body_feeder' && taskType !== 'body_dress' && taskType !== 'express'" label="负责人">
          <a-select
            v-decorator="['user_id', {
              rules: [
                { required: true, message: '请选择负责人' },
              ]
            }]"
            placeholder="请选择负责人"
            @load="loadingUserOptions"
            @change="handlePhoneNumberChange"
            allow-clear
          >
            <a-select-option
              v-for="user in userOptions"
              :key="user.id"
              :value="user.id"
            >
              {{ user.emp_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="taskType === 'express'" label="送货人">
          <a-select
            v-decorator="['user_id', {
              rules: [
                { required: true, message: '请选择送货人' },
              ]
            }]"
            placeholder="请选择送货人"
            @load="loadingUserOptions"
            @change="handlePhoneNumberChange"
            allow-clear
          >
            <a-select-option
              v-for="user in userOptions"
              :key="user.id"
              :value="user.id"
            >
              {{ user.emp_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="taskType === 'body_feeder'" label="接运人">
          <a-select
            v-decorator="['user_id', {
              rules: [
                { required: true, message: '请选择接运人' },
              ]
            }]"
            placeholder="请选择接运人"
            @load="loadingUserOptions"
            @change="handlePhoneNumberChange"
            allow-clear
          >
            <a-select-option
              v-for="user in userOptions"
              :key="user.id"
              :value="user.id"
            >
              {{ user.emp_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="taskType === 'body_dress'" label="穿衣人">
          <a-select
            v-decorator="['user_id', {
              rules: [
                { required: true, message: '请选择穿衣人' },
              ]
            }]"
            placeholder="请选择穿衣人"
            @load="loadingUserOptions"
            @change="handlePhoneNumberChange"
            allow-clear
          >
            <a-select-option
              v-for="user in userOptions"
              :key="user.id"
              :value="user.id"
            >
              {{ user.emp_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="联系电话">
          <a-input
            :disabled="true"
            v-decorator="['phone_number', {
              normalize: this.$lodash.trim
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item v-if="taskType === 'body_feeder'" label="接运车辆">
          <a-select
            v-decorator="['car_id', {
              initialValue: defaultCar.id > 0 ? defaultCar.id : undefined,
              rules: [
                { required: true, message: '请选择接运车辆' },
              ]
            }]"
            placeholder="请选择车牌号"
            @load="loadingCarOptions"
            allow-clear
          >
            <a-select-option
              v-for="car in carOptions"
              :key="car.id"
              :value="car.id"
            >
              {{ car.car_no }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="taskType === 'express'" label="交通方式">
          <a-radio-group v-decorator="['transport_type', { initialValue: transportType }]" @change="handleToInfo">
            <a-radio value="car">汽车</a-radio>
            <a-radio value="walk">步行</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item v-if="transType == 'car' && taskType === 'express'" label="汽车牌照">
          <a-select
            v-decorator="['car_id', {
              initialValue: defaultCar.id > 0 ? defaultCar.id : undefined,
            }]"
            placeholder="请选择车牌号"
            @load="loadingCarOptions"
            allow-clear
          >
            <a-select-option
              v-for="car in carOptions"
              :key="car.id"
              :value="car.id"
            >
              {{ car.car_no }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="['body_feeder', 'express'].includes(taskType)" label="备注">
          <a-textarea
            v-decorator="['remark', {
              initialValue: remark,
              rules: [
                { max: 200, message: '最多200个字符' },
              ]
            }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { updateTaskHeadUser } from '@/api/order'
import { findCarOptions } from '@/api/car'
import { findTenantTaskUserOptions, findUser } from '@/api/user'

export default {
  name: 'HeadUer',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    userId: {
      type: Number,
      required: true
    },
    carId: {
      type: Number,
      required: true
    },
    transportType: {
      type: String,
      required: true
    },
    orderType: {
      type: String,
      required: true
    },
    serviceOrderId: {
      type: Number,
      required: true
    },
    taskType: {
      type: String,
      required: true
    },
    remark: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'head_uer' }),
      submitting: false,
      userOptions: [],
      carOptions: [],
      loadingCarOptions: false,
      loadingUserOptions: false,
      data: {},
      defaultCar: {},
      defaultUser: {},
      transType: this.transportType
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchUserOptions(this.taskType)
    this.fetchCarOptions()
  },
  methods: {
    handleToInfo(e) {
      this.transType = e.target.value
    },
    fetchCarOptions() {
      this.loadingCarOptions = true
      this.carOptions = []
      findCarOptions({ order_type: this.orderType, order_id: this.serviceOrderId }).then((res) => {
        if (res.code === 0) {
          this.carOptions = res.data.option_list
          this.defaultCar = res.data.default_car
          if (this.defaultCar.id > 0) {
            this.carOptions.push(res.data.default_car)
          }
        }
        this.loadingCarOptions = false
      })
    },
    handlePhoneNumberChange(e) {
      if (e) {
        findUser(e).then((res) => {
          if (res.code === 0) {
            this.form.setFieldsValue({ phone_number: res.data.phone_number })
          }
        })
      }
    },
    fetchUserOptions(taskType) {
      this.loadingUserOptions = true
      findTenantTaskUserOptions({ task_type: taskType, order_type: this.orderType, order_id: this.serviceOrderId }).then((res) => {
        if (res.code === 0) {
          this.userOptions = res.data.option_list
          this.defaultUser = res.data.default_user
          this.form.setFieldsValue({ user_id: this.defaultUser && this.defaultUser.id > 0 ? this.defaultUser.id : undefined })
          if (this.defaultUser && this.defaultUser.id > 0) {
            findUser(this.defaultUser.id).then((res) => {
              if (res.code === 0) {
                this.form.setFieldsValue({ phone_number: res.data.phone_number })
              }
            })
          }
        }
        this.loadingUserOptions = false
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          updateTaskHeadUser(Object.assign({ task_id: this.id, order_type: this.orderType }, values)).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
